import store from "@/store";
import axios from "axios";

const ApiService = {
  apiUrl: process.env.VUE_APP_SENTIA_UPLOAD_SALA_API_URL,
  post(path, data, contentType = "application/json", params = {}) {
    const headers = {
      // Use Id token instead of access token to authorize against API Gateway, because API Gateway only accept id token.
      Authorization: store.getters.idToken,
      "Content-Type": contentType
    };
    const config = { headers, params };
    return axios.post(this.apiUrl + path, data, config);
  }
};

export default ApiService;
