import gql from "graphql-tag";

const SUBSCRIPTION_PUBLISH_CONVERSATION_ID_STATUS = gql`
  subscription MySubscription($conversation_id: ID!) {
    onPublishConversationIdStatus(conversation_id: $conversation_id) {
      conversation_id
      message
      status
      updated_at
    }
  }
`;

export { SUBSCRIPTION_PUBLISH_CONVERSATION_ID_STATUS };
