import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import ApiService from "../../../core/services/api.service";
import DialogService from "@/core/services/dialog.service";
import UtilService from "@/core/services/util.service";
import { SUBSCRIPTION_PUBLISH_CONVERSATION_ID_STATUS } from "@/core/services/graphql/subscriptions/publishConversationIdStatus";

const CONVERSATION_ID_STATUS_ENUM = {
  COMPLETE: "COMPLETE",
  FAILED: "FAILED",
  IN_PROGRESS: "IN_PROGRESS"
};

export default {
  name: "FileUpload",
  props: {
    title: {
      type: String,
      default: "File upload"
    },
    accept: {
      type: String,
      default: ".csv"
    },
    borderClass: {
      type: String,
      // More: https://getbootstrap.com/docs/4.0/utilities/borders/
      default: "border-top-info"
    },
    textClass: {
      type: String,
      // More: https://getbootstrap.com/docs/4.0/utilities/text/
      default: "text-info"
    },
    url: {
      type: String,
      required: true
    },
    contentType: {
      type: String,
      default: "text/csv"
    },
    expectedStatusCode: {
      type: Number,
      default: 200
    },
    dialogMessage: {
      type: Function
    },
    realTimeNotification: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      file: null,
      isLoading: false,
      uploadStatus: {},
      dialogTitle: "Result"
    };
  },
  methods: {
    getStatusSpinner(status) {
      return status === CONVERSATION_ID_STATUS_ENUM.IN_PROGRESS
        ? '<span class="spinner spinner-primary spinner-sm ml-2"></span>'
        : "";
    },
    getStatusClasses(status) {
      if (status === CONVERSATION_ID_STATUS_ENUM.COMPLETE) {
        return "text-success";
      } else if (status === CONVERSATION_ID_STATUS_ENUM.FAILED) {
        return "text-danger";
      } else if (status === CONVERSATION_ID_STATUS_ENUM.IN_PROGRESS) {
        return "text-primary";
      }
    },
    getDialogBodyContent(conversationId, status, message) {
      message = UtilService.convertUrlStringToAnchorTags(message);
      message = `${message ? `Message </br>${message}</br> </br>` : ""}`;
      status = `<span class="font-weight-bold ${this.getStatusClasses(
        status
      )}">${CONVERSATION_ID_STATUS_ENUM[status].replace(
        "_",
        " "
      )}</span>${this.getStatusSpinner(status)}`;

      const content = `${message}
                    Status </br>${status}</br> </br>
                    Conversation Id </br> <b class="font-size-lg">${conversationId}</b></br> </br>
                    Please keep this Conversation Id as a reference if the process does not work properly.</br> You can contact the development team on Slack: #sentia-dev-sala.`;

      return content;
    },
    subscribeToConversationStatus(conversationId) {
      if (!conversationId) {
        return;
      }

      this.$apollo.addSmartSubscription("onPublishConversationIdStatus", {
        query: SUBSCRIPTION_PUBLISH_CONVERSATION_ID_STATUS,
        variables() {
          return {
            conversation_id: conversationId
          };
        },
        result({ data }) {
          this.uploadStatus = data.onPublishConversationIdStatus;
          let content = this.getDialogBodyContent(
            conversationId,
            this.uploadStatus?.status,
            this.uploadStatus?.message
          );

          const closeButtonDisabled =
            this.uploadStatus?.status ===
            CONVERSATION_ID_STATUS_ENUM.IN_PROGRESS;

          DialogService.updateBodyContent(
            this,
            this.dialogTitle,
            content,
            closeButtonDisabled
          );
        }
      });
    },
    async uploadFile() {
      let message = `Are you sure you want to upload your file?`;
      if (
        await DialogService.confirm(this, "Confirmation", message, {
          manualControl: true,
          agreeButtonLabel: "Yes",
          cancelButtonLabel: "Cancel",
          width: 500
        })
      ) {
        this.isLoading = true;
        this.$store.dispatch(PAGE_REQUEST_DATA, this.isLoading);
        ApiService.post(this.url, this.file, this.contentType, {
          file_name: this.file.name
        })
          .then(response => {
            if (response.status !== this.expectedStatusCode) {
              return;
            }

            if (this.realTimeNotification) {
              const conversation_id =
                response.data.data.metadata?.conversation_id;
              this.subscribeToConversationStatus(conversation_id);

              DialogService.updateBodyContent(
                this,
                this.dialogTitle,
                this.getDialogBodyContent(
                  conversation_id,
                  CONVERSATION_ID_STATUS_ENUM.IN_PROGRESS,
                  "The file is being processed"
                ),
                true
              );
            } else {
              DialogService.updateBodyContent(
                this,
                this.dialogTitle,
                this.dialogMessage(response.data),
                false
              );
            }
          })
          .catch(error => {
            const errors = error?.response?.data?.errors;
            let message = errors
              ? errors[0].message
              : "Your session has expired. Please login again.";

            message = `<span class="text-danger">${message}<span>`;
            DialogService.updateBodyContent(
              this,
              this.dialogTitle,
              message,
              false
            );
          })
          .finally(() => {
            this.isLoading = false;
            this.$store.dispatch(PAGE_REQUEST_DATA, this.isLoading);
            this.file = null;
          });
      }
    }
  }
};
